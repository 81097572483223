import * as React from "react";
import AssetPage from "../../components/asset-page";

const BnbPage = () => (
  <AssetPage
    asset="BNB Smart Chain"
    ticker="BNB"
    description={[
      "BNB Smart Chain is a high-performance blockchain that runs in parallel to the BNB Beacon Chain. It aims to combine the fast transaction speeds and low fees of a centralized network with the security and decentralization of a blockchain.",
      "BNB Smart Chain combines fast block times, low fees, and EVM compatibility to offer a high-speed blockchain optimized for usability and adoption. It represents an evolution in balancing decentralization with the need for performance and scalability.",
    ]}
    hideFeatureImage
    features={[
      {
        title: "Rapid Block Times",
        text: "One main feature of BNB Smart Chain is its rapid block time of 3-5 seconds, enabled by its Proof-of-Staked-Authority consensus algorithm. With blocks finalized quickly, transactions get confirmed faster. This results in a seamless user experience for dapps, trading, and payments.",
      },
      {
        title: "Low Fees",
        text: "The fast block times and high throughput capacity of BNB Smart Chain allow it to minimize transaction fees. Average fees are just a fraction of a cent, making frequent blockchain operations like yield farming or NFT creation very economical.",
      },
      {
        title: "High-Speed DeFi",
        text: "A core feature of BNB Smart Chain is supporting decentralized finance (DeFi) applications. The fast block times and low fees make activities like trading, lending, and yield farming much cheaper and faster than on Ethereum. This has allowed DeFi projects like PancakeSwap to thrive on BNB Smart Chain.",
      },
      {
        title: "EVM Compatibility",
        text: "BNB Smart Chain is EVM-compatible, meaning it supports the Ethereum tooling and languages like Solidity that developers are familiar with. This makes it easy for Ethereum projects to be ported over and leverage the faster/cheaper transactions.",
      },
    ]}
  />
);

export default BnbPage;
